import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SplashLayout } from '../layouts';
import { setInitialVisit, setLanguage } from '../store/actions/config';
import { api } from '../services';

const SplashPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { fetchSplashData } = api();

  const changeLang = lang => dispatch(setLanguage(lang));

  const changeFlagVisit = () => dispatch(setInitialVisit());

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchSplashData();
        if (!ignore) {
          setData(response);
          changeLang(response[0].language.abbr);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [loading, fetchSplashData]);

  useEffect(() => {
    changeFlagVisit();
  }, [changeFlagVisit]);

  return (
    <SplashLayout
      data={data}
      loading={loading}
      error={error}
      onChangeLanguage={changeLang}
    />
  );
};

export default SplashPage;
